import React from 'react'
import styled from 'styled-components'

// Import Page Level Components
import NavPrimary from '../../components/layouts/NavPrimary.js'
import SecondaryNav from '../../components/layouts/NavSecondary.js'
import Footer from '../../components/layouts/Footer.js'
import TertiaryNavShipmentInTransit from '../../components/layouts/TertiaryNav/Shipments/TertiaryNavInTransit.js'


import GContentShipmentInTransit from '../../components/ContentGrids/Shipments/ShipmentInTransit.js'

const PGComponentShipmentInTransit = styled.div`
  height: 100vh;
  background-color: #fff;
  // 🖋
  font-family: 'Open Sans';
  color: #0033a1;
  // 📰
  display: grid;
  grid-template-rows: 0.1fr 0.3fr 0.2fr 5fr 0.2fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "nav_secondary"
    "nav_primary"
    "nav_tertiary"
    "g_content"
    "g_footer";

  .NavPrimary {
    grid-area: nav_primary; // 🗺
    align-self: start;
  }
  .SecondaryNav {
    grid-area: nav_secondary; // 🗺
    align-self: start;
  }
  .TertiaryNavShipment {
    grid-area: nav_tertiary; // 🗺
    align-self: start;
  }
  .GContentShipment {
    grid-area: g_content; // 🗺
  }
  .Footer {
    grid-area: g_footer; // 🗺
    align-self: end;
  }
`

const PageGridComponentShipment = () => {
  return (
    <PGComponentShipmentInTransit>
      <SecondaryNav></SecondaryNav>
      <NavPrimary></NavPrimary>
      <TertiaryNavShipmentInTransit>
        
      </TertiaryNavShipmentInTransit>

        <GContentShipmentInTransit></GContentShipmentInTransit>

      <Footer></Footer>
    </PGComponentShipmentInTransit>
  )
}

export default PageGridComponentShipment;



